.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 40px 0;

    .return {
        a {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            background-color: $color-light;
            border-radius: 10px;
            padding: 18px 30px;
            font-family: $font-family--heading;
            font-weight: 700;
            font-size: 0.938rem;
            line-height: 1.25rem;
            color: $color-dark;
            transition: all $duration;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                fill: $color-dark;
                transition: all $duration;
            }

            &:hover {
                background-color: $color-second;
            }
        }
    }
}

// .navigation_link svg {
//     position: relative;
//     top: 5px;
// }

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      min-width: 180px;

        .navigation_link {
            color: $color-dark;
            pointer-events: none;

            // Recup style ~ ligne 9
            a {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                background-color: $color-light;
                border-radius: 10px;
                padding: 18px 30px;
                font-family: $font-family--heading;
                font-weight: 700;
                font-size: 0.938rem;
                line-height: 1.25rem;
                color: $color-dark;
                transition: all $duration;
                pointer-events: auto;
            }
        }
    }

    .prev {
        .navigation_link {
          display: flex;
          justify-content: flex-start;

            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-dark;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        .navigation_link {
                display: flex;
                justify-content: flex-end;

            a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-dark;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {

                a {
                    background-color: $color-second;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 960
@media screen and (max-width: $medium) {

    .post_navigation {

        &--container {
            gap: 20px 0;
        }

        .prev, .next {
            // A changer en fonction de la taille du bouton "suivant"
            min-width: 137px;

            .navigation_link a {
                padding: 18px 20px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .post_navigation {

        .prev, .next {
            min-width: auto;

            .navigation_link a {
                padding: 18px 15px;
            }
        }

        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
            width: 100%;

            a {
                width: 100%;
                justify-content: center;
            }

        }
    }
}