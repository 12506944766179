.card--document {
    .container-center {
        .button {
            color: $color-gray;
            border: $btn-border-width $btn-border-style $color-gray; 
            margin: 35px auto 40px;
            
            &:hover {
                color: $color-white;
                background: $color-gray; 
            }
        }
    }
}
