.document-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    width: 100%;

    &__title {
        margin: 0 !important;
    }

    &__infos {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        row-gap: 15px;
        width: 100%;
        max-width: 100%;
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 10px;
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        height: auto;
        color: $color--card;
        
        &:after {
            content: '';
            display: inline-block;
            vertical-align: bottom;
            margin: auto 12px;
            width: 1px;
            height: 15px;
            background-color: $color-bg--neutral;
        }

        &--extension {
            text-transform: uppercase;
        }
    }

    &__actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }

    // Card documents avec image
    &--image {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: unset;
        gap: 25px 30px;
    }

    // Card documents avec image -- link-document--feuilleter
    &__link {
        flex: none;
        // Border only with white card
        // border: 1px $btn-border-style $color-light;
    }

    // Card documents avec image -- figure
    &__image {
        height: 220px;
        max-width: 315px;
        margin: 0;
        line-height: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    // Card documents avec image -- bloc details + actions btns
    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 25px;
        width: 100%;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 960
@media screen and (max-width: $medium) {

    .document-details {
        &--image {
            flex-wrap: wrap;
        }

        // Card documents avec image -- link-document--feuilleter
        &__link {
            max-width: 100%;
        }

    }

}


// 640
@media screen and (max-width: $small) {

    .document-details {
        &__image {
            max-width: 100%;
            height: 182px;
        }

        &__item {
            &:after {
                margin: auto 5px;
            }
        }
    }

}
