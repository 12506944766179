//======================================================================================================
// Colors
//======================================================================================================

$chatbot-content-bg                     : $color-white;
$chatbot-header-bg                      : $color-main;
$chatbot-footer-bg                      : $color-light;
$chatbot-formcontainer-bg               : $color-black--rgba;
$chatbot-radius                         : 10px;
$chatbot-shadow                         : drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
$chatbot-question-bg                    : $color-main;
$chatbot-question-color                 : $color-white;
$chatbot-footer-shadow                  : 0px -1px 0px rgba(119, 119, 119, 0.2);
