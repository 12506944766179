.cover {
    position: relative;
    margin-bottom: 55px;

    &__image-wrapper {
        position: relative;
        left: calc(50% - 665px);
        width: 1665px;
        height: 550px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: 150px 0px 0px 25px;
        }
    }

    &__container {
        margin-top: -65px;
        position: relative;
        z-index: 1;
    }

    &__informations {
        background: $color-light;
        border-radius: 40px;
        padding: 65px 60px 55px;
    }

    &__title {
        color: $color-main;
        margin: 0 0 15px;
        font-family: $font-family--heading;
        font-weight: 900;
        font-size: 3.125rem;
        line-height: 3.438rem;
    }

    &__lead-wrapper {
        p {
            font-family: $font-family;
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 1.563rem;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 450px;
            width: 1365px;
            left: calc(50% - 515px);

            img {
                border-radius: 120px 0px 0px 25px;
            }
        }

        &__informations {
            padding: 50px 60px 55px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 370px;
            left: calc(50% - 395px);
            width: 1125px;
        }

        &__informations {
            padding: 50px 40px 40px;
        }

        &__title {
            font-size: 2.813rem;
            line-height: 3.125rem;
        }

        &__lead-wrapper {
            p {
                font-size: 1.125rem;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__image-wrapper {
            height: 260px;
            left: calc(50% + -320px);
            width: 640px;

            img {
                border-radius: 0;
            }
        }

        &__container {
            margin-top: -50px;
        }

        &__informations {
            padding: 50px 0 40px;
            border-radius: 0;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 640px;
                height: 100%;
                top: 0;
                left: calc(50% + -320px);
                background-color: $color-light;
                z-index: -1;
            }
        }
    }

}
