.footer {
    color: $color-white;
    overflow: hidden;

    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 150px;
            height: 150px;
            right: 15px;
            top: -45px;
            border-radius: 100px;
            background-color: $color-light;
            opacity: .2;
            z-index: 1;
        }

        &:after {
            position: absolute;
            content: "";
            width: 80px;
            height: 60px;
            right: 11px;
            top: 63px;
            background-image: url(/wp-content/themes/ambronay/assets/src/images/sprite-images/home/illu_footer.svg);
            background-repeat: no-repeat;
            z-index: 1;
        }
    }

    &__infos {
        display: flex;
        flex-wrap: wrap;
        padding: 80px 0 60px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 1665px;
            height: 100%;
            right: calc(50% - 665px);
            top: 0;
            border-radius: 0px 100px 25px 0px;
            background-color: $color-main;
            z-index: -1;
        }

        &:after {
            position: absolute;
            content: "";
            width: 1658px;
            height: 100px;
            right: calc(50% - 665px);
            bottom: 55px;
            background-image: url(/wp-content/themes/ambronay/assets/src/images/sprite-images/home/ligne_ville.svg);
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__coordonnees {
        width: 300px;
        margin-right: 86px;

        .footer__text {
            margin-bottom: 30px;
        }
    }

    &__horaires {
        width: 300px;
        margin-right: 115px;
    }

    &__contact {
        width: 330px;
        height: 50px;
        padding: 0 55px!important;
        color: $color-text!important;
        background-color: $color-second!important;
        margin-top: 65px;

        svg {
            fill: $color-text!important;
        }

        &:hover, &:focus {
            background-color: $color-light!important;
        }
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.563rem;
        margin-bottom: 20px;
    }

    &__text {
        font-family: $font-family;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.563rem;
    }

    &__phone {
        font-weight: 600;

        &:hover, &:focus {
            color: $color-second;
        }
    }

    &__pictos {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 5px;
    }

    &__picto {
        font-family: $font-family;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.563rem;
        color: $color-white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 15px;
        transition: all $duration ease-in-out;

        svg {
            width: 40px;
            height: 40px;
            background-color: $color-second;
            fill: $color-dark;
            border-radius: 10px;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
           color: $color-light;
            
            svg {
                background-color: $color-white;
                fill: $color-main;
            }
        }
    }

    &__menu {
        padding: 20px 0;
        background: $color-white;
        display: flex;
        align-items: center;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 50px;
            width: 100%;
        }

        li {
            a {
                font-family: $font-family;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: $color-dark;
                opacity: 1;

                &:hover, &:focus {
                    color: $color-dark;
                    opacity: .4;
                }
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        &__container {
            &::before {
                top: -75px;
            }

            &:after {
                top: 40px;
            }
        }

        &__infos {
            padding: 80px 0 65px;

            &:before {
                right: calc(50% - 545px);
            }

            &:after {
                bottom: 59px;
                width: 1538px;
                right: calc(50% - 545px);
            }
        }

        &__coordonnees {
            margin-right: 40px;
            width: 240px;

            .footer__text {
                margin-bottom: 25px;
            }
        }

        &__horaires {
            margin-right: 40px;
        }

        &__contact {
            width: 260px;
            padding: 0 30px !important;
        }

        &__menu ul {
            gap: 35px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        &__container {
            &:after {
                right: 6px;
            }
        }

        &__infos {
            flex-direction: column;
            align-items: center;
            gap: 30px;
            padding: 60px 0 80px;

            &:before {
                right: calc(50% - 390px);
            }

            &:after {
                bottom: 15px;
                width: 1743px;
                right: calc(50% - 390px);
            }
        }

        &__coordonnees {
            margin-right: 0;
            text-align: center;
        }

        &__horaires {
            margin-right: 0;
            text-align: center;
        }

        &__pictos {
            order: 4;
            flex-direction: row;
            margin-top: 0;
        }

        &__picto {
            span {
                display: none;
            }
        }

        &__contact {
            margin-top: 0;
        }

        &__title {
            margin-bottom: 10px;
        }

        &__menu {
            padding: 32px 0;
            flex-wrap: wrap;
            justify-content: center;
            gap: 30px;
            width: 500px;
            margin: auto;

            ul {
                justify-content: center;
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        &__container {
            &:before, &:after {
                display: none;
            }
        }

        &__menu {
            width: 100%;
            gap: 35px;
            
            ul {
                gap: 30px;
            }
        }
    }

}