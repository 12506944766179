.header {
    position: relative;

    &-top {
        height: 120px;
        background-color: $color-white;
        transition: all $duration ease-in-out;

        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }

        &__title {
            margin: 0;
            font-size: 0;
            line-height: 0;
            
            svg {
                fill: $color-main;
                width: 270px;
                height: 70px;
            }
        }

        &__forme {
            width: 150px;
            height: 100%;
            position: relative;
            overflow: hidden;
            margin-left: -50px;

            &:before {
                position: absolute;
                content: " ";
                left: 0;
                bottom: -80px;
                width: 100%;
                height: 150px;
                border-radius: 100px;
                background-color: $color-second;
                opacity: 0.5;
                transition: all $duration ease-in-out;
            }
        }
    }

    &--fixed {
        z-index: 20;
        
        .header-top {
            box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
            left: 0;
            padding: 0;
            position: fixed;
            right: 0;
            top: 0;
            height: 100px;

            &__forme {
                &:before {
                    bottom: -90px;
                }
            }

            .nav-main .nav-main-item .nav-main-link {
                height: 100px;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link {
    display: none;
}

.tools {
    display: flex;
    align-content: center;
    gap: 15px;
    max-height: 40px;

    .tool {
        margin: 0;
        padding: 0;
        background-color: $color-main;
        border-radius: 10px;
        border: none;
        width: 40px;
        height: 40px;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-white;
            width: 100%;
            height: 100%;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-second;

            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover, &:focus {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border: none;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $color-main;
            color: $color-white;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &-top {
            &__container {
                justify-content: unset;
            }

            &__title {
                svg {
                    width: 232px;
                    height: 60px;
                }
            }

            &__forme {
                width: 100px;
                margin-left: 0;

                &:before {
                    height: 100px;
                    bottom: -50px;
                }
            }
        }

        &--fixed {
            .header-top__forme::before {
                bottom: -60px;
            }
        }
    }

    .tools {
        gap: 10px;
        margin-left: 40px;
    }

    .privacy {
        &__container {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        &__button {
            margin: 0;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .tools {
        margin-left: 47px;
    }
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        height: 175px;

        &__container {
            flex-wrap: wrap;
            align-items: unset;
        }

        &__logo {
            margin: 25px auto 0;
        }

        &__forme {
            display: none;
        }
    }

    .tools {
        margin-left: 26px;
        margin-top: -5px;
    }
}
