.front-page {
    &__section {
        overflow: hidden;
        height: 380px;
        padding-top: 65px;
        margin-top: -65px;

        .container {
            display: flex;
        }
    }

    &__title {
        margin: 0;
        padding: 0;
        font-family: $font-family--heading;
        font-weight: 900;
        font-size: 3.125rem;
        line-height: 3.438rem;
        text-transform: lowercase;
        
        &:after {
            display: none;
        }

        &--small {
            margin: 0;
            padding: 0;

            &:after {
                display: none;
            }
        }
    }

    &__button {
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 0.938rem;
        line-height: 1.25rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $color-white;
        border-radius: 10px;
        background-color: $color-main;
        padding: 10px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-white;
            width: 30px;
            height: 30px;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-light;
            color: $color-main;

            svg {
                fill: $color-main;
            }
        }
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .slideshow {
        height: 550px;
        width: 100%;

        &__slide {
            position: relative;
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
            border-radius: 150px 0px 0px 25px;
        }

        &__container {
            display: inherit;
            position: relative;
            height: 100%;
            width: 1665px;
            left: calc(50% - 665px);
            position: absolute;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            right: 30px;
            bottom: 50px;
            width: 500px;
            height: auto;
            padding: 20px;
            background: $color-second--rgba;
            color: $color-white;
        }

        &__title {
            margin-bottom: 15px;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 95px;
            right: 5px;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                margin: 0 10px 0 0;
                transition: .25s;
                background-color: $color-white;
                border-radius: 50px;
                opacity: 1;
                border: 0px $color-white solid;
    
                &:hover, &:focus {
                    background-color: $color-main;
                }
    
                &-active {
                    background-color: transparent;
                    border-width: 1px;
                }
            }
        }
    
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    background-color: $color-light;
    border-radius: 0px 0px 0px 50px;
    box-sizing: border-box;
    padding: 50px 0 55px 50px;
    width: 735px;
    height: 275px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(/wp-content/themes/ambronay/assets/src/images/sprite-images/home/motif.png);
        background-repeat: repeat;
        border-radius: 0px 0px 0px 50px;
        z-index: 0;
    }

    &__blocs {
        display: flex;
        flex-wrap: wrap;
        gap: 39.5px 60px;
        position: relative;
        z-index: 1;
    }

    &__bloc {
        display: flex;
        align-items: center;
        gap: 15px;
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.563rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        width: 295px;
        cursor: pointer;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-third;
            width: 30px;
            height: 30px;
            border-radius: 10px;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            gap: 9px;

            svg {
                fill: $color-main;
            }
        }
    }
}

//======================================================================================================
// Démarches 
//======================================================================================================

.demarches {
    position: relative;
    width: 435px;
    margin-top: -65px;
    padding: 50px 0 65px 50px;
    z-index: 1;
    background-color: $color-third;
    border-radius: 50px 0px 0px 20px;

    &:before {
        position: absolute;
        content: " ";
        width: 100px;
        height: 76px;
        top: 90px;
        right: 20px;
        background-image: url(/wp-content/themes/ambronay/assets/src/images/sprite-images/home/illu_demarches.svg);
        background-repeat: no-repeat;
        z-index: 1;
    }

    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 750px;
            height: 380px;
            left: calc(50% + 190px);
            top: -50px;
            background-color: $color-third;
            z-index: 1;
        }

        &:after {
            position: absolute;
            content: " ";
            width: 150px;
            height: 150px;
            right: -205px;
            bottom: -100px;
            border-radius: 100px;
            opacity: 0.2;
            background-color: $color-light;
            z-index: 1;
        }
    }

    &__title {
        color: $color-light;
        font-size: 2.5rem;
        line-height: 3.125rem;
    }

    &__blocs {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__bloc {
        font-family: $font-family;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.563rem;
        color: $color-white;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-white;
            width: 20px;
            height: 20px;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            color: $color-light;

            svg {
                fill: $color-light;
                transform: rotate(90deg);
            }
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    margin-top: 40px;

    &__container {
        position: relative;

        &::before {
            position: absolute;
            content: "";
            width: 235px;
            height: 235px;
            top: 5px;
            left: 265px;
            border-radius: 100%;
            background-color: $color-light;
            opacity: 0.5;
            z-index: -1;
        }
    }

    &__title {
        color: $color-text;
        display: flex;
        align-items: center;
        gap: 20px;

        svg {
            fill: $color-main;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &__item {
        position: relative;

        &--big {
            width: 550px;
        }

        &--small {
            width: 590px;
            display: flex;
            align-items: flex-end;
            gap: 30px;
        }

        &:hover, &:focus {
            .news__taxonomy {
                background-color: $color-main;
                color: $color-light;
            }

            .news__image img {
                transform: scale(1.025);
            }

            .news__plus {
                svg {
                    background-color: $color-main;
                }
            }
        }
    }

    &__image {
        overflow: hidden;

        &--big {
            width: 100%;
            height: 370px;
        }

        &--small {
            width: 290px;
            min-width: 290px;
            height: 170px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            transform: scale(1);
            transition: all $duration ease-in-out;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat rgb(229, 236, 231);
        background-position: center;
        border-radius: 5px;

        &--big {
            width: 550px;
            height: 370px;
        }

        &--small {
            width: 290px;
            height: 170px;
        }
    }

    &__titlep {
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.563rem;
        color: $color-dark;

        &--big {
            padding: 30px 0 10px;
        }
    }

    &__introduction {
        color: $color-dark;
        line-height: 1.563rem;
    }

    &__plus {
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: $font-family;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.563rem;
        margin-top: 20px;

        svg {
            width: 20px;
            height: 20px;
            fill: $color-white;
            background-color: $color-second;
            border-radius: 5px;
            transition: all $duration ease-in-out;
        }
    }

    &__taxonomy {
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        color: $color-main;
        background-color: $color-light;
        border-radius: 10px;
        padding: 10px 15px;
        max-width: 250px;
        position: absolute;
        left: 15px;
        transition: all $duration ease-in-out;

        &--big {
            top: 345px;

        }

        &--small {
            top: 145px;
        }
    }

    &__button {
        margin-top: 40px;
    }
}

//======================================================================================================
// Annuaire
//======================================================================================================

.annuaire {
    margin-top: 60px;
    overflow: hidden;
    position: relative;

    &:before {
        position: absolute;
        content: " ";
        width: 80px;
        height: 95px;
        right: 45px;
        top: 30px;
        background-image: url(/wp-content/themes/ambronay/assets/src/images/sprite-images/home/illu_annuaire.svg);
        background-repeat: no-repeat;
        z-index: 1;
    }

    &:after {
        position: absolute;
        content: " ";
        width: 150px;
        height: 150px;
        right: 80px;
        top: -62px;
        border-radius: 100px;
        opacity: 0.2;
        background-color: $color-third;
        z-index: 1;
    }
    
    &__container {
        background-color: $color-light;
        padding: 40px 105px 60px 50px;
        border-radius: 50px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-image: url(/wp-content/themes/ambronay/assets/src/images/sprite-images/home/motif.png);
            background-repeat: repeat;
            border-radius: 50px;
            z-index: 0;
        }
    }

    &__title {
        position: relative;
        z-index: 1;
        color: $color-main;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        margin: 32px 0 30px;
        position: relative;
        z-index: 1;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
    }

    &__label {
        font-weight: 400;
        width: 440px;
        margin-top: 0;
        font-family: $font-family;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.563rem;
        color: $color-dark;
        
        span {
            font-weight: 700;
        }
    }

    &__category {
        display: flex;
        gap: 10px;
    }

    &__select {
        border-radius: 10px;
        width: 455px;
        height: 50px;
        position: relative;

        select {
            border: none;
            appearance: none;
            color: $color-white;
            background-color: $color-third;
            font-family: $font-family--heading;
            font-weight: 700;
            font-size: 0.938rem;
            line-height: 1.25rem;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            width: 100%;
            height: 100%;
            padding: 0 30px;
            transition: all $duration ease-in-out;
        }

        svg {
            fill: $color-white;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 30px;
            top: 10px;
            pointer-events: none;
        }

        &:focus {
            select {
                background-color: $color-white;
                cursor: pointer;
            }
        }
    }

    &__btn {
        width: 50px;
        height: 50px;
        background-color: $color-third;
        border-radius: 10px;
        color: $color-white;
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 0.938rem;
        line-height: 1.25rem;
        letter-spacing: 0.05em;
        margin: 0;
        padding: 0;
        border: none;

        &:hover, &:focus {
            background-color: $color-main;
            color: $color-white;
        }
    }

    &__button {
        width: 515px;
        height: 50px;
        letter-spacing: 0.05em;
        padding: 12px 30px;

        &:hover, &:focus {
            background-color: $color-second;
            color: $color-text;

            svg {
                fill: $color-text;
            }
        }
    }
}

//======================================================================================================
// Documents
//======================================================================================================

.home-documents {
    margin: 80px 0;

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        display: flex;

        &--big {
            width: 545px;
            gap: 30px;
            position: relative;

            &:before {
                position: absolute;
                content: " ";
                width: 300px;
                height: 300px;
                left: 135px;
                bottom: -130px;
                border-radius: 100%;
                background-color: $color-light;
                opacity: 0.5;
                z-index: -1;
            }
        }

        &--small {
            flex-direction: column;
            width: 590px;
        }
    }

    &__content {
        background-color: $color-third;
        padding: 30px 30px 20px;
        border-radius: 20px;
        display: flex;
        gap: 30px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 80px;
            height: 60px;
            right: 30px;
            bottom: 25px;
            background-image: url(/wp-content/themes/ambronay/assets/src/images/sprite-images/home/illu_kiosque.svg);
            background-repeat: no-repeat;
            z-index: 1;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        &--big {
            margin-top: 53px;
            height: fit-content;
        }
    }

    &__btn {
        margin: 0;

        svg {
            background: none;
            margin: 0;
            width: 100%;
            height: 100%;
        }

        &--big {
            width: 40px;
            height: 40px;
            border-radius: 10px;
            background-color: $color-second;

            svg {
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-light;
                
                svg {
                    background-color: transparent;
                    fill: $color-main;
                }
            }
        }

        &--small {
            width: 30px;
            height: 30px;
            border-radius: 8px;
            background-color: $color-light;

            svg {
                fill: $color-main;
            }

            &:hover, &:focus {
                background-color: $color-main;
                
                svg {
                    background-color: transparent;
                    fill: $color-white;
                }
            }
        }
    }

    &__image {
        width: 175px;
        min-width: 175px;
        height: 265px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
        }

        &--notimg {
            background-color: rgb(229, 236, 231);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 95px;
                height: 70px;
            }
        }
    }

    &__infos {
        &--big {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    &__title {
        color: $color-main;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__taxonomy {
        font-family: $font-family--heading;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        display: flex;
        gap: 15px;
        max-width: 280px;

        span {
            display: flex;
            width: 10px;
            height: 1.5px;
            margin-top: 10px;
        }

        &--big {
            color: $color-text;

            span {
                background-color: $color-text;
            }
        }

        &--small {
            color: $color-white;

            span {
                background-color: $color-white
            }
        }
    }

    &__titlep {
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.563rem;
        max-width: 240px;

        &--big {
            color: $color-dark;
            margin: 20px 0 40px;
        }

        &--small {
            color: $color-white;
            margin: 15px 0 10px;
        }
    }

    &__date {
        font-family: $font-family;
        font-style: italic;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;

        &--big {
            color: $color-dark;
            position: absolute;
            left: 0;
            bottom: 0
        }

        &--small {
            color: $color-white;
        }
    }

    &__button {
        margin-top: 30px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home-slideshow {
        .slideshow {
            height: 450px;

            img, video {
                border-radius: 120px 0px 0px 25px;
            }

            &__container {
                width: 1365px;
                left: calc(50% - 515px);
            }

            &__pagination {
                right: 0;
            }
        }
    }

    .access {
        width: 620px;

        &__blocs {
            gap: 39.5px 55px;
        }

        &__bloc {
            font-size: 1rem;
            width: 250px;
        }
    }

    .demarches {
        width: 320px;
        padding: 50px 0 65px 40px;
        border-radius: 50px 0px 0px 20px;

        &:before {
            right: -55px;
            top: 110px;
            z-index: 2;
        }

        &__container {
            &:before {
                left: calc(50% + 140px);
            }

            &:after {
                right: -200px;
            }
        }

        &__bloc {
            font-size: 1rem;
        }
    }

    .news {
        &__container {
            &:before {
                left: 260px;
            }
        }

        &__list {
            gap: 60px;
        }

        &__content {
            gap: 25px;
        }

        &__item {
            &--big {
                width: 440px;
            }

            &--small {
                width: 480px;
                gap: 20px;
            }
        }

        &__image {
            &--big {
                height: 300px;
            }
    
            &--small {
                width: 235px;
                height: 140px;
                min-width: 235px;
            }
        }
    
        .notimg {
            &--big {
                width: 440px;
                height: 300px;
            }
    
            &--small {
                width: 235px;
                height: 140px;
            }
        }

        &__taxonomy {
            &--big {
                top: 275px;
            }

            &--small {
                top: 120px;
            }
        }

        &__button {
            margin-top: 10px;
            width: 475px;
        }
    }

    .annuaire {
        &:before {
            right: 30px;
            top: 18px;
        }

        &:after {
            right: 65px;
            top: -75px;
        }

        &__container {
            padding: 40px 35px 58px 50px;
        }

        &__select {
            width: 365px;
        }

        &__button {
            width: 425px;
        }

        &__label {
            font-size: 0.938rem;
            width: 400px;
        }
    }

    .home-documents {
        &__item {
            &--big {
                width: 495px;
                gap: 20px;
            }

            &--small {
                width: 420px;
            }
        }

        &__content {
            padding: 30px 20px 20px;
            gap: 20px;

            &:before {
                right: 20px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        &__section {
            height: 420px;
        }

        &__button {
            font-size: 0.875rem;
        }
    }

    .home-slideshow {
        position: relative;

        .slideshow {
            height: 370px;

            &__container {
                left: calc(50% - 395px);
                width: 1125px;
            }

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__pagination {
                bottom: 75px;
            }
        }
    }

    .access {
        padding: 27px 0 27px 40px;
        margin-left: -40px;
        width: 375px;
        height: 330px;

        &__blocs {
            gap: 20px;
        }

        &__bloc {
            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 4;
            }

            &:nth-child(3) {
                order: 2;
            }

            &:nth-child(4) {
                order: 5;
            }

            &:nth-child(5) {
                order: 3;
            }

            &:nth-child(6) {
                order: 6;
            }
        }
    }

    .demarches {
        width: 310px;
        height: 400px;
        padding: 50px 0 65px 29px;
        margin-top: -45px;

        &:before {
            right: -75px;
        }

        &__container {
            &:before {
                left: calc(50% + 135px);
                height: 400px;
            }

            &:after {
                right: -220px;
            }
        }
    }

    .news {
        margin-top: 60px;

        &__content {
            flex-direction: column;
            gap: 35px;
        }

        &__list {
            display: flex;
            flex-direction: row;
            gap: 20px;
            flex-wrap: wrap;
        }

        &__item {
            &--big {
                width: unset;
                display: flex;
                gap: 20px;
            }

            &--small {
                flex-direction: column;
                width: 300px;
            }
        }

        &__image {
            &--big {
                width: 300px;
                height: 205px;
                min-width: 300px;
            }
    
            &--small {
                width: 300px;
                height: 180px;
            }
        }
    
        .notimg {
            &--big {
                width: 300px;
                height: 205px;
                min-width: 300px;
            }
    
            &--small {
                width: 300px;
                height: 180px;
            }
        }

        &__taxonomy {
            &--big {
                top: 180px;
            }

            &--small {
                top: 155px;
            }
        }

        &__titlep {
            &--big {
                padding: 0 0 10px;
            }

            &--small {
                margin-top: 5px;
            }
        }

        &__button {
            margin-top: 30px;
            width: 100%;
        }
    }

    .annuaire {
        &__container {
            padding: 40px 50px 60px;
        }

        &__top {
            flex-direction: column;
            gap: 20px;
            margin: 30px 0 40px;
        }

        &__select {
            width: 460px;

            select {
                font-size: 0.875rem;
            }
        }

        &__label {
            width: 460px;
        }

        &__bottom {
            flex-direction: column;
            gap: 20px;
        }

        &__button {
            width: 100%;
        }
    }

    .home-documents {
        &__list {
            flex-direction: column;
            gap: 40px;
        }

        &__item {
            width: 100%;

            &--big {
                gap: 30px;

                &:before {
                    left: 155px;
                }
            }
        }

        &__infos {
            width: 265px;

            &--big {
                height: 245px;
            }
        }

        &__content {
            padding: 30px 30px 20px;

            &:before {
                right: 30px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .front-page {
        &__section {
            margin-top: 0;
            padding-top: 0;
            height: unset;

            .container {
                flex-direction: column;
            }
        }
    }

    .home-slideshow {
        .slideshow {
            height: 260px;

            &__container {
                left: 0;
                width: 100%;

                img, video {
                    border-radius: 0;
                }
            }

            &__content {
                left: 30px;
                right: 30px;
                width: auto;
            }

            &__pagination {
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                right: unset;
            }
        }
    }

    .access {
        background-color: transparent;
        width: 100%;
        height: 100%;
        margin-left: 0;
        padding: 40px 0 42px 0;

        &:before {
            width: 640px;
            left: calc(50% + -320px);
            z-index: 0;
        }

        &:after {
            position: absolute;
            content: "";
            width: 640px;
            height: 100%;
            top: 0;
            left: calc(50% + -320px);
            background-color: $color-light;
            z-index: -1;
        }
    }

    .demarches {
        height: 100%;
        margin-top: 0;
        padding: 30px 0 55px 0;
        width: 100%;
        border-radius: 0;
        background-color: transparent;

        &:before {
            right: -38px;
            top: 80px;
        }

        &__container {
            &:before {
                height: 350px;
                left: calc(50% + -320px);
                top: -30px;
                width: 640px;
                z-index: -1;
            }

            &:after {
                right: -190px;
                bottom: -90px;
            }
        }

        &__title {
            font-size: 2.5rem;
            line-height: 3.125rem;
        }
    }

    .news {
        &__title {
            font-size: 2.813rem;
            line-height: 3.438rem;

            svg {
                display: none;
            }
        }

        &__item {
            &--big {
                flex-direction: column;
                gap: 25px;
            }
        }

        &__button {
            margin-top: 15px;
        }
    }

    .annuaire {
        &:before, &:after {
            display: none;
        }
        
        &__container {
            padding: 30px 20px 60px;
            border-radius: 30px;
        }

        &__top {
            gap: 10px;
            margin: 30px 0;
        }

        &__bottom {
            gap: 30px;
        }

        &__select {
            width: 200px;

            select {
                font-size: 0.75rem;
                line-height: 1.25rem;
                padding: 0 20px;
            }

            svg {
                right: 20px;
                top: 10px;
            }
        }

        &__label {
            width: 260px
        }

        &__button {
            font-size: 0.75rem;
            line-height: 1.25rem;
            padding: 0 20px;
        }
    }

    .home-documents {
        margin: 65px 0 80px;
        position: relative;

        &__list {
            gap: 30px;
        }

        &__item {
            &--big {
                flex-wrap: wrap;
                gap: 25px;
                padding-top: 80px;

                &::before {
                    left: 150px;
                    top: 135px;
                    bottom: unset;
                }
            }
        }

        &__content {
            padding: 25px 20px;

            &:before {
                display: none;
            }
        }

        &__infos {
            &--big {
                position: unset;
                width: 100%;
                height: fit-content;
                text-align: center;
            }
        }

        &__title {
            left: 50%;
            transform: translateX(-50%);
        }

        &__taxonomy {
            &--big {
                max-width: unset;
                display: flex;
                justify-content: center;
            }
        }

        &__titlep {
            &--big {
                max-width: unset;
            }
        }

        &__date {
            &--big {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
