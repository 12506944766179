.nav-main {
    z-index: 10;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        gap: 80px;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &:hover, &:focus {
            .nav-main-link {
                color: $color-main;

                &:before {
                    opacity: 1;
                }

                svg {
                    fill: $color-main;
                }
            }
        }

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: flex-start;
            }
        }

        &:last-child {
           .nav-main-link {
                &:after {
                    width: 0;
                    height: 0;
                    background: transparent;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            height: 120px;
            color: $color-dark;
            text-transform: uppercase;
            font-family: $font-family--heading;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.25rem;
            letter-spacing: 0.05em;
            transition: all $duration ease-in-out;

            &:before {
                position: absolute;
                content: " ";
                bottom: -12px;
                left: calc(50% + 15px);
                transform: translateX(-50%);
                width: 25px;
                height: 25px;
                border-radius: 50px;
                background-color: $color-light;
                z-index: 2;
                opacity: 0;
            }

            svg {
                width: 20px;
                height: 20px;
                transition: all $duration ease-in-out;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            right: 0;
            z-index: 1;
            padding: 30px 15px;
            background: $color-main;
            border-radius: 20px 0 0 20px;
            width: 1665px;
            left: calc(50% - 665px);

            &__close {
                display: none;
                justify-content: flex-start;
                align-items: center;
                flex-shrink: 0;
                margin: 0;
                padding: 0;
                background-color: transparent;
                border: none;
                transition: $duration;
                font-family: $font-family--heading;
                font-size: 1.25rem;
                font-weight: 700;
                letter-spacing: .05em;
                line-height: 1.25rem;
                color: $color-white;

                svg {
                    width: 40px;
                    height: 30px;
                    fill: $color-white;
                    transform: rotate(180deg);
                    transition: $duration;
                }

                &:hover {
                    color: $color-white;
                }
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
                width: 1200px;
                margin: 0;
                margin-left: 50px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;
                transition: all $duration ease-in-out;

                a {
                    position: relative;
                    display: flex;
                    align-items: flex-start;
                    gap: 5px;
                    width: 100%;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    padding: 12.5px 30px 12.5px 0;
                    color: $color-white;
                    font-family: $font-family;
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 1.563rem;

                    svg {
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                        fill: $color-white;
                        margin-top: -3px;
                    }
                }

                &:hover {
                    margin-left: 15px;
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-weight: 400;
                } 
            }
        }
    }

    // Responsive button
    &__button {
        display: none;
    }

    // Responsive button label
    &__icon {
        position: relative;
        display: none;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
        margin: 0;
        padding: 21px 0;
        background-color: transparent;
        color: $color-dark;
        text-transform: uppercase;
        transition: $duration;
        cursor: pointer;
        font-family: $font-family--heading;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.25rem;
        letter-spacing: 0.05em;

        svg {
            width: 20px;
            height: 30px;
            fill: $color-dark;
        }
    }

    // For Responsive (close menu open)
    &__close {
        display: none;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 7px 40px 0 auto;
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: $border-radius--round;
        transition: all $duration ease-in-out;

        svg {
            width: 100%;
            height: 100%;
            margin: 0;
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover {
            background-color: transparent;
            svg {
                fill: $color-white;
            }
        }
    }
    
}

// For Responsive (no scroll on body when menu is open)
body.js-menu-active {
    overflow: hidden;
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        margin-left: 18px;

        .menu__nav__list {
            gap: 40px;
        }

        .nav-main-item .sub-menu {
            width: 1365px;
            left: calc(50% - 515px);

            ul {
                margin: 0 0 0 20px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .nav-main {
        position: relative;
        margin-left: 38px;

        // Responsive button label
        .nav-main__icon {
            display: flex;
        }

        &__container {
            position: fixed;
            top: 0;
            left: inherit;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-second;
            padding: 40px 0;
            transition: $duration;
            overflow-y: auto;
        }

        &--active {
            .nav-main__container {
                right: 0;
            }
        }
        
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
        }

        .menu__nav__list {
            flex-direction: column;
            flex-wrap: nowrap;
            // overflow-y: auto;
            width: 100%;
            padding: 0 40px;
        }

        .nav-main-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%!important;
            border-bottom: 1px solid rgb(255 255 255 / 30%);

            &::after {
                content: '';
                height: 20px;
                width: 40px;
                mask-image: url(/wp-content/themes/ambronay/assets/src/images/sprite-images/Pictos/ic_fleche.svg);
                mask-repeat: no-repeat;
                background-color: $color-white;
                transition: all $duration;
            }

            .sub-menu {
                left: inherit;
            }

            &.menu-item-has-children {

                &:focus-within .sub-menu, 
                &:hover .sub-menu {
                    justify-content: flex-start;
                }

                &.js-active {   
                    .sub-menu {
                        right: 0;
                    }
                }
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;

                .nav-main-link {
                    color: $color-white;
                }
            }

            .nav-main-link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-white;

                &:before {
                    display: none;
                }

                svg {
                    fill: $color-white;
                }

                &:hover, &:focus, &:active {
                    background-color: $color-bg--transparent;
                    color: $color-white;

                    svg {
                        fill: $color-white!important;
                    }
                }
            }

            .sub-menu {
                top: 0;
                right: 100%;
                z-index: 1;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                width: 100vw;
                height: 100vh;
                padding: 40px 0 0 0;
                background-color: $color-second;
                transition: $duration;

                &::after {
                    display: none;
                }

                &__close {
                    display: flex;
                    margin-left: 50px;
                    margin-top: 15px;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    max-width: 100%;
                    padding: 0 40px 100px;
                    overflow-y: auto;
                    
                }

                .nav-child-item, .nav-grandchild-item {
                    margin: auto;

                    a {
                        color: $color-white;

                        &::after {
                            display: none;
                        }

                        svg {
                            display: none;
                        }
                    }

                    &:hover {
                        margin-left: 0;
                    }
                    
                }
            }

        }

        
    }
    
}


// 640
@media screen and (max-width: $small) {
    .nav-main {
        margin-left: 30px;

        &__icon {
            padding: 0;
        }
    }
}
