//=================================================================================================
// Buttons in general
//=================================================================================================
#{$btn} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin: 5px 14px 5px 0;
    padding: 15px 30px;
    background: $color-main;
    border: none;
    border-radius: 10px;
    font-family: $font-family--heading;
    font-weight: 700;
    font-size: 0.938rem;
    line-height: 1.25rem;
    letter-spacing: 0.05em;
    color: $color-white;
    text-transform: $btn-text-transform;
    text-decoration: none;
    text-align: center;
    transition: all $duration ease;

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        color: $color-main;
        background: $color-light;

        svg {
            fill: $color-main;
        }
    }

    svg {
        width: 26px;
        height: 26px;
        fill: $color-white;
        transition: all $duration ease;
    }
}


//=================================================================================================
// Buttons specifics
//=================================================================================================
.button {
    &--filter,
    &--toggler,
    &--toggler:active,
    &--toggler:focus,
    &--tag,
    &--tag--medium,
    &--tag--low,
    &--empty {
        font-family: $font-family;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding: $btn-spe-padding;
        height: 40px;
    }


    //===============================================
    // Button secondary (borders, neutral)
    //===============================================
    &--secondary {
        color: $color-main;
        background: $color-light;

        svg {
            fill: $color-main;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $color-white;
            background-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }


    //===============================================
    // Button variant 1 (Background and color change)
    //===============================================
    &--variant {
        color: $btn-variant-color;
        background: $btn-variant-bg;
        border-color: $btn-variant-border-color;

        svg {
            fill: $btn-variant-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-variant-color--hover;
            background: $btn-variant-bg--hover;
            border-color: $btn-variant-border-color--hover;

            svg {
                fill: $btn-variant-color--hover;
            }
        }
    }


    //===============================================
    // Button filter
    //===============================================
    &--filter {
        color: $color-dark;
        background: $color-light;
        margin-right: 10px;

        svg { 
            fill: $btn-filter-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-filter-color--hover;
            background: $btn-filter-bg--hover;
        }
    }


    //===============================================
    // Button toggler
    //===============================================
    &--toggler,
    &--toggler:active,
    &--toggler:focus {
        color: $color-dark;
        background: $btn-toggler-bg;
        border: 2px solid $color-dark;

        svg {
            fill: $color-dark;
        }

        &.is-open {
            svg {
            transform: rotate(180deg);
            }
        }

        &:hover {
            color: $color-white;
            background: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }


    //===============================================
    // Button tag
    //===============================================
    &--tag,
    &--tag--medium,
    &--tag--low {
        margin: 0 5px 10px 0;
        background-color: $color-third;
        color: $color-white;

        &:hover, &:focus {
            background-color: $color-light;
            color: $color-dark;
        }
    }

    &--tag--medium {
        opacity: 0.8;
    }

    &--tag--low {
        opacity: 0.6;
    }


    //===============================================
    // Button empty
    //===============================================
    &--empty {
        background: transparent;
        border: 2px solid $color-main;
        color: $color-main;

        svg {
            fill: $color-main;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background: $color-main;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }
}


//===============================================
// Specifics links
//===============================================
.link-document {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    cursor: pointer;
    font-family: $font-family--heading;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $color--card;
    transition: all $duration ease;

    svg {
        width: 30px;
        height: 30px;
        background: $color-second;
        border-radius: 8px;
        fill: $color-text;
        margin-right: 10px;
        transition: all $duration ease;
    }

    &:hover {
        svg {
            background-color: $color-main;
            fill: $color-white;
        }
    }
}


//===============================================
// Animation Voir plus
//===============================================
.showX {
    opacity: 1;
    transition: all 0.30s ease-out;
}

.hideX {
    opacity: 0;
    transition: all 0.30s ease-in;
    pointer-events: none;
}

.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

//===============================================
// Reset button
//===============================================
.reset-button-style {
    display: inherit;
    margin: 0;
    padding: 0;
    height: auto;
    background: inherit;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
    text-align: inherit;
    transition: none;

    svg {
        width: inherit;
        height: inherit;
        fill: inherit;
        margin: 0;
        transition: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background: inherit;
        border-color: inherit;
        color: inherit;

        svg {
            fill: inherit;
        }
    }
}